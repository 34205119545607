import React from "react"
import PropTypes from "prop-types"
import classnames from "classnames"
import "./subtitle-description.scss"

const SubtitleDescription = ({ className, ...props }) => (
  <section
    className={classnames(
      "subtitle-description",
      "is-size-4-desktop",
      "is-size-5-mobile",
      "has-text-centered",
      className
    )}
  >
    <div className="content is-family-secondary">{props.children}</div>
  </section>
)

SubtitleDescription.propTypes = {
  className: PropTypes.string,
}

export default SubtitleDescription
