import React from "react"
import CompetenceCard from "../../molecules/competence-card/competence-card"
import SectionTitle from "../../atoms/section-title/section-title"
import CompetenceContentAdministratif from "../../molecules/competence-content/competence-content-administratif"
import CompetenceContentEtrangers from "../../molecules/competence-content/competence-content-etrangers"
import CompetenceContentAffaires from "../../molecules/competence-content/competence-content-affaires"
import CompetenceContentFamille from "../../molecules/competence-content/competence-content-famille"
import CompetenceContentPenal from "../../molecules/competence-content/competence-content-penal"
import CompetenceContentTravail from "../../molecules/competence-content/competence-content-travail"
import "./competences-page-description.scss"

const CompetencesPageDescription = () => (
  <section className="section competences-page-description">
    <div className="container">
      <SectionTitle title="compétences" />
      <div className="columns is-centered">
        <CompetenceCard
          className="column is-4"
          type="administratif"
        >
          <CompetenceContentAdministratif />
        </CompetenceCard>

        <CompetenceCard
          className="column is-4"
          type="etrangers"
        >
          <CompetenceContentEtrangers />
        </CompetenceCard>
        <CompetenceCard
          className="column is-4"
          type="affaires"
        >
          <CompetenceContentAffaires />
        </CompetenceCard>
      </div>
      <div className="columns is-centered">
        <CompetenceCard
          className="column is-4"
          type="famille"
        >
          <CompetenceContentFamille />
        </CompetenceCard>

        <CompetenceCard
          className="column is-4"
          type="penal"
        >
          <CompetenceContentPenal />
        </CompetenceCard>

        <CompetenceCard
          className="column is-4"
          type="travail"
        >
          <CompetenceContentTravail />
        </CompetenceCard>
      </div>
    </div>
  </section>
)

export default CompetencesPageDescription
