import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/atoms/seo"
import PageTitle from "../components/atoms/page-title/page-title"
import SubtitleDescription from "../components/atoms/subtitle-description/subtitle-description"
import PageDivider from "../components/atoms/page-divider/page-divider"
import CompetencesPageDescription from "../components/organisms/competences-page-description/competences-page-description"

const CompetencesPage = () => {
  const { allWordpressPage } = useStaticQuery(
    graphql`
      query {
        allWordpressPage(
          filter: { title: { eq: "competences-presentation" } }
        ) {
          edges {
            node {
              id
              title
              content
            }
          }
        }
      }
    `
  )
  return (
    <>
      <Layout>
        <SEO title="Mopo Avocats Competences" />
        <PageTitle title="compétences" />
        <SubtitleDescription>
          {allWordpressPage.edges[0]?.node.content && (
            <div
              dangerouslySetInnerHTML={{
                __html: allWordpressPage.edges[0]?.node.content,
              }}
            />
          )}
        </SubtitleDescription>
        <PageDivider />
        <CompetencesPageDescription />
      </Layout>
    </>
  )
}

export default CompetencesPage
