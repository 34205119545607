import React from "react"
import PropTypes from "prop-types"
import classnames from "classnames"
import RedirectLink from "../../atoms/redirect-link/redirect-link"
import administratif from "./administratif.svg"
import etrangers from "./etrangers.svg"
import affaires from "./affaires.svg"
import famille from "./famille.svg"
import penal from "./penal.svg"
import travail from "./travail.svg"
import "./competence-card.scss"

const CompetenceCard = ({ type, redirect, className, ...props }) => {
  let img, title
  switch (type) {
    case "administratif": {
      title = "Droit administratif"
      img = administratif
      break
    }
    case "etrangers": {
      title = "Droit des étrangers, d’asile et de nationalité"
      img = etrangers
      break
    }
    case "affaires": {
      title = "Droit des affaires, des baux d'habitation et commerciaux"
      img = affaires
      break
    }
    case "famille": {
      title = "Droit de la famille, des enfants et de succession"
      img = famille
      break
    }
    case "penal": {
      title = "Droit pénal et de la circulation routière"
      img = penal
      break
    }
    case "travail": {
      title = "Droit social et du travail"
      img = travail
      break
    }
    default:
  }
  return (
    <div className={classnames("card", className)}>
      <div className="card-image">
        <figure className="image is-128x128">
          <img src={img} alt="type" />
        </figure>
      </div>
      <div className="car-header">
        <div className="card-header-title is-family-secondary has-text-weight-normal has-text-centered is-centered is-size-3-desktop is-size-4-mobile">
          {title}
        </div>
      </div>
      <div className="card-content">
        <div className="content is-family-secondary">{props.children}</div>
      </div>
      {redirect ? <RedirectLink className="is-right" link={redirect} /> : null}
    </div>
  )
}

CompetenceCard.propTypes = {
  type: PropTypes.string.isRequired,
  redirect: PropTypes.string,
  classNames: PropTypes.string,
}

export default CompetenceCard
