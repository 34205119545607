import React from "react"
import classnames from "classnames"
import { Link } from "gatsby"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faLongArrowAltRight } from "@fortawesome/free-solid-svg-icons"
import "./redirect-link.scss"

const RedirectLink = ({ link, className }) => (
  <Link
    className={classnames(
      "redirect-link",
      "is-family-secondary",
      "has-text-weight-light",
      "is-size-5-desktop",
      "is-size-6-mobile",
      className
    )}
    to={link}
  >
    Lire la suite
    <div className="redirect-arrow">
      <FontAwesomeIcon className="redirect-arrow" icon={faLongArrowAltRight} />
    </div>
  </Link>
)

export default RedirectLink
