import React from "react"
import { graphql, useStaticQuery } from "gatsby"

const CompetenceContentEtrangers = ({ className }) => {
  const { allWordpressPage } = useStaticQuery(
    graphql`
      query {
        allWordpressPage(
          filter: { title: { eq: "competence-etrangers" } }
        ) {
          edges {
            node {
              id
              title
              content
            }
          }
        }
      }
    `
  )
  return (
    <div
      className={className}
      dangerouslySetInnerHTML={{
        __html: allWordpressPage.edges[0]?.node.content,
      }}
    />
  )
}

export default CompetenceContentEtrangers
